import {
	Auth,
	getAuth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	setPersistence,
	browserSessionPersistence,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
} from 'firebase/auth';
import { app } from '../utils/general';

export class AuthService {
	private auth: Auth;
	private googleAuthProvider: GoogleAuthProvider;
	private facebookAuthProvider: FacebookAuthProvider;

	constructor() {
		this.auth = getAuth(app);

		this.auth.languageCode = document.documentElement.lang || 'it';

		this.googleAuthProvider = new GoogleAuthProvider();
		this.facebookAuthProvider = new FacebookAuthProvider();

		this.checkAuth();
	}

	private async loginUser(): Promise<boolean> {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		const idToken = await this.auth.currentUser.getIdToken();
		const response = await fetch('/api/auth/loginUser.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
		});

		//if response.statuscode == 422 then show error message
		if (response.status === 422) {
			const data = await response.json();
			throw new Error(data.message);
		}

		if (!response.ok) {
			throw new Error('Failed to login user');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to login user');
		}

		return data.loginExpired;
	}

	private async logoutUser(): Promise<boolean> {
		const response = await fetch('/api/auth/logoutUser.php');

		if (!response.ok) {
			throw new Error('Failed to logout user');
		}

		const data = await response.json();
		if (!data.success) {
			throw new Error('Failed to logout user');
		}

		return data.wasLoggedIn;
	}

	private async checkAuth(): Promise<void> {
		onAuthStateChanged(this.auth, async (user) => {
			if (user) {
				try {
					const shouldReload = await this.loginUser();

					if (shouldReload) {
						window.location.reload();
					}
				} catch (error) {
					console.error(error);
				}
			} else {
				try {
					const shouldReload = await this.logoutUser();

					if (shouldReload) {
						window.location.reload();
					}
				} catch (error) {
					console.error(error);
				}
			}
		});
	}

	public async signIn(email: string, password: string): Promise<void> {
		await signInWithEmailAndPassword(this.auth, email, password);
		await this.loginUser();
	}

	public async signInWithGoogle(): Promise<void> {
		await signInWithPopup(this.auth, this.googleAuthProvider);
		await this.loginUser();
	}

	public async signInWithFacebook(): Promise<void> {
		await signInWithPopup(this.auth, this.facebookAuthProvider);
		await this.loginUser();
	}

	public async setPersistenceToBrowserSession(): Promise<void> {
		await setPersistence(this.auth, browserSessionPersistence);
	}

	public signOut(): void {
		this.auth.signOut();
	}

	public isLoggedIn(): boolean {
		return this.auth.currentUser !== null;
	}
}
