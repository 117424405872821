import { FirebaseError } from 'firebase/app';
import type { AuthService } from '../auth';
import { getFirebaseErrorMessage } from '../../utils/general';
import * as m from '../../../../../src/paraglide/messages';
import { signInSchema } from '../../utils/validation';
import { ZodError } from 'zod';

export class AuthHandler {
	private authService: AuthService;

	constructor(authService: AuthService) {
		this.authService = authService;
	}

	private showLoadingIndicators(form: HTMLFormElement): {
		loadingBtn: HTMLElement | null;
		submitBtn: HTMLElement | null;
	} {
		const loadingBtn: HTMLElement | null = form.querySelector('.spinner-button');
		const submitBtn: HTMLElement | null = form.querySelector('button[type="submit"]');

		if (loadingBtn && submitBtn) {
			loadingBtn.classList.remove('d-none');
			submitBtn.classList.add('d-none');
		}

		return { loadingBtn, submitBtn };
	}

	private hideLoadingIndicators(
		loadingBtn: HTMLElement | null,
		submitBtn: HTMLElement | null,
	): void {
		if (loadingBtn && submitBtn) {
			loadingBtn.classList.add('d-none');
			submitBtn.classList.remove('d-none');
		}
	}

	/**
	 * Extracts the error message from the error object, if it is a known error type or a generic error message
	 * @instance ZodError : Will return the error messages from the ZodError object separated by a new line
	 * @instance FirebaseError : Will return the error message from the FirebaseError object
	 * @instance Error : Will return the error message from the Error object
	 * @param error - The error object
	 */
	private extractErrorMessage(error: unknown): string {
		if (error instanceof FirebaseError) {
			return getFirebaseErrorMessage(error.code);
		}
		if (error instanceof ZodError) {
			return error.errors.map((err) => err.message).join('\n');
		}
		if (error instanceof Error) {
			return error.message;
		}
		return m.unknown_error();
	}

	private displayErrorMessage(form: HTMLFormElement, message: string): void {
		let errorContainer: HTMLElement | null = form.querySelector('.alert-error');

		if (!errorContainer) {
			errorContainer = document.createElement('div');
			errorContainer.classList.add(
				'alert',
				'alert-error',
				'alert-danger',
				'alert-dismissible',
				'd-none',
			);
			form.prepend(errorContainer);
		}

		errorContainer.innerHTML = `
            <h4 class="alert-title">
                <i class="w-icon-exclamation-triangle"></i>Oops!</h4>
            ${m.auth_error_heading()}
            <p>${message.replace(/\n/g, '<br>')}</p>
        `;

		errorContainer.classList.remove('d-none');
	}

	public async loginFormSubmitHandler(event: CustomEvent): Promise<void> {
		const form: HTMLFormElement | undefined = event.detail?.form;

		if (!form) return;

		const formData: FormData = new FormData(form);

		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		try {
			//validate the form data using zod if errors return them
			const { email, password } = await signInSchema.parseAsync({
				email: formData.get('email') as string,
				password: formData.get('password') as string,
			});

			await this.authService.signIn(email, password);
			const rememberMe: boolean | undefined = (
				form.querySelector('input[name="rememberMe"]') as HTMLInputElement
			)?.checked;

			if (!rememberMe) {
				await this.authService.setPersistenceToBrowserSession();
			}

			await new Promise((resolve) => setTimeout(resolve, 500));
			window.location.reload();
		} catch (error) {
			const message = this.extractErrorMessage(error);
			this.displayErrorMessage(form, message);

			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	public async loginWithGoogleHandler(): Promise<void> {
		try {
			await this.authService.signInWithGoogle();
			await new Promise((resolve) => setTimeout(resolve, 500));
			window.location.reload();
		} catch (error) {
			const message = this.extractErrorMessage(error);
			const form = document.querySelector('form');
			if (form) {
				this.displayErrorMessage(form as HTMLFormElement, message);
			} else {
				alert(message);
			}
		}
	}

	public async loginWithFacebookHandler(): Promise<void> {
		try {
			await this.authService.signInWithFacebook();
			await new Promise((resolve) => setTimeout(resolve, 500));
			window.location.reload();
		} catch (error) {
			const message = this.extractErrorMessage(error);
			const form = document.querySelector('form');
			if (form) {
				this.displayErrorMessage(form as HTMLFormElement, message);
			} else {
				alert(message);
			}
		}
	}
}
